import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import {
    MySquadPlayer,
    OpportunityItem,
    MatchingPlayer,
} from 'api/transfers-out/models/transfers-out';
import TransfersOutService from 'api/transfers-out/transfers-out.service';
import historyAccessor from 'history-accessor';
import { userPaths } from 'routes/paths';
import { getAuthSquadId, getAuthUserId } from 'store/auth/authReducer';
import { MySquadToBuy, MySquadToLoan } from 'api/my-squad/model/my-squad-player';
import TransfersOutPlayerDatailsService from 'api/transfers-out/player-datails/transfers-out-player-datails.service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PlayerRequirementMismatch } from 'api/pitch-v2/models/check-player-requirements';
import { AdvisoryModalService } from 'api/pitch-v2/advisory-modal/advisory-modal.service';

export enum PitchStepEnum {
    PitchScreen,
    PitchedList,
}

export enum PaginationClickState {
    Number = 1,
    Left = 2,
    Right = 3,
}

class TransfersOutPitchedPlayerState {
    isLoading: boolean;
    isPitchProcessing: boolean;
    currentStep: PitchStepEnum;
    pitchesAd: OpportunityItem;
    playerPitchesAds: OpportunityItem[];
    currentItems: OpportunityItem[];
    selectedPlayer: MySquadPlayer;
    mySquadPlayers: MySquadPlayer[];
    isPlayerAvailable: boolean;
    amount: number;
    pageSize: number;
    currentPage: number;
    totalResultCount: number;
    totalPageCount: number;
    paginationState: PaginationClickState;
    isMatchingPlayerBlock: boolean;
    mismatches: PlayerRequirementMismatch[];
    isPlayerAdMismatchModelOpen: boolean;
}

const defaultState: TransfersOutPitchedPlayerState = {
    isLoading: false,
    isPitchProcessing: false,
    currentStep: PitchStepEnum.PitchScreen,
    pitchesAd: null,
    playerPitchesAds: null,
    currentItems: null,
    selectedPlayer: null,
    mySquadPlayers: null,
    isPlayerAvailable: true,
    amount: null,
    pageSize: 10,
    currentPage: 1,
    totalResultCount: 0,
    totalPageCount: 0,
    paginationState: PaginationClickState.Number,
    isMatchingPlayerBlock: true,
    mismatches: [],
    isPlayerAdMismatchModelOpen: false,
};

const stateController = new StateController<TransfersOutPitchedPlayerState>(
    'PITCH_PAGE/SELECTED_PLAYER',
    defaultState,
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        };
    }
    public static setStep(step: PitchStepEnum) {
        return (dispatch) => {
            dispatch(stateController.setState({ currentStep: step }));
        };
    }

    public static initPitches(adId: number, playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                await dispatch(Actions.getMySquad());
                await dispatch(Actions.getPitchesAd(adId));

                const players = Selectors.getRoot(getState()).mySquadPlayers;
                const mySquadDataPlayer = players.find((item) => item.id === playerId);
                const selectedPlayerWithRelevance = Selectors.getRoot(getState()).pitchesAd?.playersWithRelevance.find(item => item.player.id === playerId);

                dispatch(
                    stateController.setState({
                        selectedPlayer: mySquadDataPlayer,
                        mySquadPlayers: players,
                        isMatchingPlayerBlock: selectedPlayerWithRelevance.relevance >= 0.6,
                    }),
                );
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static getPlayerPitchesAds() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                const { selectedPlayer } = Selectors.getRoot(getState());
                const { opportunities } = await TransfersOutService.getPlayerPitchesAds([
                    selectedPlayer.id,
                ]);
                dispatch(
                    stateController.setState({
                        playerPitchesAds: opportunities,
                    }),
                );

                dispatch(Actions.initPagination());
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static setIsPlayerAvailable(value: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isPlayerAvailable: value }));
        };
    }
    public static setAmount(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState({
                    amount: value,
                }),
            );
        };
    }

    public static redirectToPitchOpportunities() {
        return (dispatch, getState: () => AppState) => {
            historyAccessor.push(userPaths.pitchOpportunities);
            dispatch(Actions.dispose());
        };
    }
    public static cancelPitch() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.redirectToPitchOpportunities());
        };
    }

    public static skipPitchPotentialScreen() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.redirectToPitchOpportunities());
        };
    }

    public static pitchPlayer(
        playerAdId: number,
        playerId: number,
        amount: number,
        toBuy: MySquadToBuy,
        toLoan: MySquadToLoan,
    ) {
        return async (dispatch, getState: () => AppState) => {
            const { isPlayerAvailable } = Selectors.getRoot(getState());

            dispatch(Actions.closePlayerAdMismatchModal());

            dispatch(stateController.setState({ isPitchProcessing: true }));
            try {
                const currentUserId = getAuthUserId(getState());
                const currentSquadId = getAuthSquadId(getState());

                await TransfersOutPlayerDatailsService.pitchPlayer(
                    currentUserId,
                    playerAdId,
                    amount,
                    playerId,
                    currentSquadId,
                    null,
                );

                if (isPlayerAvailable) {
                    await TransfersOutPlayerDatailsService.saveAvailability(
                        playerId,
                        toBuy,
                        toLoan,
                    );
                }

                await dispatch(Actions.getPlayerPitchesAds());

                const { pitchesAd, playerPitchesAds, mySquadPlayers } =
                    Selectors.getRoot(getState());
                const selectedPlayer = mySquadPlayers.find((item) => item.id === playerId);

                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        selectedPlayer: selectedPlayer,
                        pitchesAd: pitchesAd,
                        playerPitchesAds: playerPitchesAds,
                        isPlayerAvailable: defaultState.isPlayerAvailable,
                    })),
                );

                if (playerPitchesAds.length) {
                    dispatch(Actions.setStep(PitchStepEnum.PitchedList));
                } else {
                    dispatch(Actions.skipPitchPotentialScreen());
                }
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isPitchProcessing: false }));
            }
        };
    }

    public static checkPlayerRequirements({
        playerAdId,
        playerId,
    }: {
        playerAdId: number;
        playerId: number;
    }) {
        return async (dispatch) => {
            dispatch(stateController.setState({ isPitchProcessing: true }));

            try {
                const { mismatches } = await AdvisoryModalService.checkPlayerRequirements(
                    playerAdId,
                    playerId,
                );

                const isPlayerHasMismatchesWithAd = mismatches.length > 0;

                if (isPlayerHasMismatchesWithAd) {
                    dispatch(
                        stateController.setState({
                            mismatches,
                            isPlayerAdMismatchModelOpen: true,
                        }),
                    );
                }
            } finally {
                dispatch(stateController.setState({ isPitchProcessing: false }));
            }
        };
    }

    public static closePlayerAdMismatchModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ isPlayerAdMismatchModelOpen: false }));
        };
    }

    public static onPlayerSelect(player: MatchingPlayer) {
        return (dispatch, getState: () => AppState) => {
            const { pitchesAd, mySquadPlayers } = Selectors.getRoot(getState());
            const selectedPlayer = mySquadPlayers.find((item) => item.id === player.player.id);

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    selectedPlayer: selectedPlayer,
                    isPlayerAvailable: defaultState.isPlayerAvailable,
                    amount: defaultState.amount,
                    pitchesAd: pitchesAd,
                    isMatchingPlayerBlock: player.relevance >= 0.6,
                })),
            );
            historyAccessor.replace(
                `${userPaths.pitchPage}?playerId=${selectedPlayer.id}&adId=${pitchesAd.playerAd.id}`,
            );
        };
    }

    public static getMySquad() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { players } = await TransfersOutService.getMySquad();

                dispatch(
                    stateController.setState({
                        mySquadPlayers: players,
                    }),
                );
            } catch (error) {
                console.error(error);
            }
        };
    }

    public static openPitchPlayerPage(opportunity: OpportunityItem) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                const { selectedPlayer } = Selectors.getRoot(getState());
                await dispatch(Actions.getPitchesAd(opportunity.playerAd.id));

                dispatch(
                    stateController.setState({
                        selectedPlayer: selectedPlayer,
                        amount: defaultState.amount,
                    }),
                );

                dispatch(Actions.setStep(PitchStepEnum.PitchScreen));
                historyAccessor.replace(
                    `${userPaths.pitchPage}?playerId=${selectedPlayer.id}&adId=${opportunity.playerAd.id}`,
                );
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static getPitchesAd(adId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { opportunities } = await TransfersOutService.getPitchesAd([adId]);

                dispatch(
                    stateController.setState({
                        pitchesAd: opportunities[0],
                    }),
                );
            } catch (error) {
                console.error(error);
            }
        };
    }

    public static setPage = (page: number) => {
        return (dispatch, getState: () => AppState) => {
            const { paginationState, pageSize, playerPitchesAds } = Selectors.getRoot(getState());
            const itemOffset = ((page - 1) * pageSize) % playerPitchesAds.length;

            dispatch(
                stateController.setState((prevState) => {
                    return {
                        ...prevState,
                        currentPage: page,
                        currentItems: playerPitchesAds.slice(itemOffset, itemOffset + pageSize),
                    };
                }),
            );

            let message = '';
            switch (paginationState) {
                case PaginationClickState.Number:
                    message = `Moved to Page: ${page}`;
                    break;
                case PaginationClickState.Left:
                    message = `Moved to Previous Page: ${page}`;
                    break;
                case PaginationClickState.Right:
                    message = `Moved to Next Page: ${page}`;
                    break;
            }

            dispatch(
                userActivityInsert({
                    PageName: 'Pitch Opportunities [Pagination]',
                    Message: message,
                    // PageType: PageType.
                }),
            );
        };
    };

    public static setPageSize = (page: number, pageSize: number) => {
        return (dispatch, getState: () => AppState) => {
            const { playerPitchesAds } = Selectors.getRoot(getState());
            const itemOffset = ((page - 1) * pageSize) % playerPitchesAds.length;

            dispatch(
                stateController.setState((draftState) => {
                    return {
                        ...draftState,
                        pageSize,
                        currentPage: page,
                        totalPageCount: Math.ceil(playerPitchesAds.length / pageSize),
                        currentItems: playerPitchesAds.slice(itemOffset, itemOffset + pageSize),
                    };
                }),
            );

            dispatch(
                userActivityInsert({
                    PageName: 'Pitch Opportunities [Pagination]',
                    Message: `Selected ${pageSize} rows`,
                    // PageType: PageType
                }),
            );
        };
    };

    public static paginationSetState(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ paginationState: value }));
        };
    }

    public static initPagination() {
        return (dispatch, getState: () => AppState) => {
            const { playerPitchesAds, pageSize } = Selectors.getRoot(getState());
            dispatch(
                stateController.setState({
                    pageSize: pageSize,
                    currentPage: 1,
                    totalResultCount: playerPitchesAds.length,
                    totalPageCount: Math.ceil(playerPitchesAds.length / pageSize),
                    currentItems: playerPitchesAds.slice(0, pageSize),
                }),
            );
        };
    }

    public static validateAndPitchPlayer({
        playerAdId,
        playerId,
        amount,
        toBuy,
        toLoan,
    }: {
        playerAdId: number;
        playerId: number;
        amount: number;
        toBuy: MySquadToBuy;
        toLoan: MySquadToLoan;
    }) {
        return async (dispatch, getState: () => AppState) => {
            try {
                await dispatch(
                    Actions.checkPlayerRequirements({
                        playerAdId,
                        playerId,
                    }),
                );
                if (!Selectors.isPlayerAdMismatchOpen(getState())) {
                    dispatch(Actions.pitchPlayer(playerAdId, playerId, amount, toBuy, toLoan));
                }
            } catch (e) {
                console.error(e);
            }
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersOut.pitchedPlayer;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).currentStep;
    public static getSelectedPlayer = (state: AppState) => Selectors.getRoot(state).selectedPlayer;
    public static currentItems = (state: AppState) => Selectors.getRoot(state).currentItems;
    public static getCurrentPage = (state: AppState) => Selectors.getRoot(state).currentPage;
    public static getPageSize = (state: AppState) => Selectors.getRoot(state).pageSize;
    public static getTotalRowCount = (state: AppState) => Selectors.getRoot(state).totalResultCount;
    public static getTotalPageCount = (state: AppState) => Selectors.getRoot(state).totalPageCount;
    public static isPlayerAdMismatchOpen = (state: AppState) =>
        Selectors.getRoot(state).isPlayerAdMismatchModelOpen;
    public static playerMismatches = (state: AppState) => Selectors.getRoot(state).mismatches;
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    TransfersOutPitchedPlayerState as State,
    Actions as Actions,
    stateController as Controller,
};
