import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import TransfersOutService from 'api/transfers-out/transfers-out.service';
import { OpportunityItem, OpportunityItemWithRelevance, MySquadPlayer } from 'api/transfers-out/models/transfers-out';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { PitchSortByKeyEnum } from 'api/transfers-out/models/transfers-out';
import { Actions as FilterActions, Selectors as FilterSelectors } from 'pages/club/pitch-opportunities/redux/filter.controller';
import historyAccessor from 'history-accessor';
import positionFullNames from 'constants/positionFullNames';
import { SearchPlayerPositionEnum } from 'api/search-v3/model/player-positions';
import { getAuthClubId, getAuthSquadId } from 'store/auth/authReducer';
import { userPaths } from 'routes/paths';

export enum PaginationClickState {
    Number = 1,
    Left = 2,
    Right = 3,
}

class SortItem {
    id: PitchSortByKeyEnum;
    title: string;
    shortTitle: string;
    sortDirection: 'asc' | 'desc';
}

const sortInfoMap = new Map<PitchSortByKeyEnum, SortItem>([
    [PitchSortByKeyEnum.MostRecent, {
        id: PitchSortByKeyEnum.MostRecent,
        title: 'Most recent',
        shortTitle: 'Most recent',
        sortDirection: 'desc'
    }],
    [PitchSortByKeyEnum.TransferFeeLowToHigh, {
        id: PitchSortByKeyEnum.TransferFeeLowToHigh,
        title: 'Transfer fee: High to low',
        shortTitle: 'Transfer fee',
        sortDirection: 'desc',
    }],
    [PitchSortByKeyEnum.TransferFeeHighToLow, {
        id: PitchSortByKeyEnum.TransferFeeHighToLow,
        title: 'Transfer fee: Low to high',
        shortTitle: 'Transfer fee',
        sortDirection: 'asc'
    }],
    [PitchSortByKeyEnum.ByRelevance, {
        id: PitchSortByKeyEnum.ByRelevance,
        title: 'Relevance',
        shortTitle: 'Relevance',
        sortDirection: 'desc'
    }],
]);

export const pitchOpportunitiesSortList: Array<SortItem> = [
    sortInfoMap.get(PitchSortByKeyEnum.MostRecent),
    sortInfoMap.get(PitchSortByKeyEnum.TransferFeeLowToHigh),
    sortInfoMap.get(PitchSortByKeyEnum.TransferFeeHighToLow),
    sortInfoMap.get(PitchSortByKeyEnum.ByRelevance)
];

class ClubPitchOpportunitiesState {
    isLoading: boolean;
    isProcessing: boolean;
    deletedAdProcessingIds: number[];
    result: OpportunityItem[];
    opportunities: OpportunityItemWithRelevance[];
    currentItems: OpportunityItemWithRelevance[];
    filteredOpportunities: OpportunityItemWithRelevance[];
    pageSize: number;
    currentPage: number;
    totalResultCount: number;
    totalPageCount: number;
    paginationState: PaginationClickState;
    sortBy?: PitchSortByKeyEnum;
    players: MySquadPlayer[];
    relevancePlayerId: number;
    pitchedPlayer: OpportunityItemWithRelevance;
}

const defaultState: ClubPitchOpportunitiesState = {
    isLoading: false,
    isProcessing: false,
    deletedAdProcessingIds: [],
    result: [],
    opportunities: [],
    currentItems: [],
    filteredOpportunities: [],
    pageSize: 10,
    currentPage: 1,
    totalResultCount: 0,
    totalPageCount: 0,
    paginationState: PaginationClickState.Number,
    sortBy: PitchSortByKeyEnum.MostRecent,
    players: [],
    relevancePlayerId: null,
    pitchedPlayer: null,
}

const stateController = new StateController<ClubPitchOpportunitiesState>(
    'CLUB/PITCH_OPPORTUNITIES_GRID',
    defaultState
);

class Actions {
    public static dispose() {
        return dispatch => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            const isActiveAnyFilter = FilterSelectors.isActiveAnyFilter(getState())
            try {
                dispatch(stateController.setState({ isLoading: true }));
                await dispatch(Actions.getInitialData());
                if (isActiveAnyFilter) {
                    dispatch(Actions.applyFilters());
                }
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static getInitialData() {
        return async (dispatch) => {
            try {
                await dispatch(Actions.getOpportunities());
                await dispatch(FilterActions.loadPlayerPositions());
                await dispatch(Actions.getMySquad());
                dispatch(Actions.initPagination());
                dispatch(Actions.initSorting());
            } catch (error) {
                console.error(error);
            }
        }
    }

    public static refresh() {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));
                await dispatch(Actions.getOpportunities());
                dispatch(Actions.initPagination());
                dispatch(Actions.initSorting());
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    private static getClubPlayers(currentClubId: number, currentSquadId: number, players: MySquadPlayer[]) {
        let playerArrayData = [];

        for (const player of players) {
            if (player.firstPositionCode === null) {
                continue;
            }

            if (!player.parentSquad) {
                continue;
            }

            if (
                player.parentSquad.clubId === null &&
                currentClubId === 0 &&
                player.parentSquad.id === currentSquadId
            ) {
                playerArrayData.push(player);
            } else if (player.parentSquad.clubId === currentClubId) {
                playerArrayData.push(player);
            }
        }
        return playerArrayData;
    };

    public static getMySquad() {
        return async (dispatch, getState: () => AppState) => {
            const appState = getState();
            const currentSquadId = getAuthSquadId(appState);
            const currentClubId = getAuthClubId(appState);
            try {
                const data = await TransfersOutService.getMySquad();

                const players = Actions.getClubPlayers(currentClubId, currentSquadId, data.players);

                dispatch(stateController.setState({
                    players: players,
                }));
            } catch (error) {
                console.error(error);
            }
        }
    }

    public static getOpportunities() {
        return async (dispatch) => {
            try {
                const selectedPlayer = -1; // TODO: change to selected player in filters controller
                const data = await TransfersOutService.getOpportunities();

                const opportunities = data.opportunities.map(op => {
                    const targetPlayer =
                        selectedPlayer === -1
                            ? op.matchingPlayer
                            : op.playersWithRelevance.find(p => p.player.id === selectedPlayer);

                    return {
                        ...op,
                        relevance: targetPlayer?.relevance ?? 0,
                        selectedPlayerId: op.matchingPlayer ? op.matchingPlayer.player.id : op.playersWithRelevance[0].player.id,
                    };
                })

                dispatch(stateController.setState({
                    result: data.opportunities,
                    opportunities: opportunities,
                    filteredOpportunities: opportunities,
                }));
            } catch (error) {
                console.error(error);
            }
        }
    }

    public static setSelectedPlayersRelevance() {
        return (dispatch, getState: () => AppState) => {
            try {
                const { filteredOpportunities } = Selectors.getRoot(getState());
                const selectedPlayerId = FilterSelectors.getRoot(getState()).playerFilterModal.selectedPlayerId;

                const opportunities = filteredOpportunities.map(op => {
                    const targetPlayer = op.playersWithRelevance.find(p => p.player.id === selectedPlayerId);

                    return {
                        ...op,
                        relevance: targetPlayer?.relevance ?? 0,
                    };
                })

                dispatch(stateController.setState({
                    filteredOpportunities: opportunities,
                }));
            } catch (error) {
                console.error(error);
            }
        }
    }

    public static onBackButtonClick() {
        return (dispatch, getState: () => AppState) => {
            dispatch(FilterActions.clearAllFilters());
            historyAccessor.goBack();
        }
    }

    public static initSorting() {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const opportunities = Selectors.getOpportunities(getState());
            const opportunitiesSorted = opportunities.sort((a, b) => {
                if (a.isDismissed !== b.isDismissed) {
                    return a.isDismissed ? 1 : -1;
                }
                return (
                    Date.parse(b.playerAd.updatedAt) -
                    Date.parse(a.playerAd.updatedAt)
                );
            })
            dispatch(stateController.setState({
                totalPageCount: Math.ceil(opportunitiesSorted.length / subState.pageSize),
                currentItems: opportunitiesSorted.slice(0, subState.pageSize),
                sortBy: PitchSortByKeyEnum.MostRecent,
            }));
        }
    }

    public static initPagination() {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const opportunities = Selectors.getOpportunities(getState());
            dispatch(stateController.setState({
                pageSize: subState.pageSize,
                currentPage: 1,
                totalResultCount: opportunities.length,
                totalPageCount: Math.ceil(opportunities.length / subState.pageSize),
                currentItems: opportunities.slice(0, subState.pageSize)
            }));
        }
    }

    public static setPage = (page: number) => {
        return (dispatch, getState: () => AppState) => {
            const paginationState = Selectors.getRoot(getState()).paginationState;
            const { pageSize, filteredOpportunities } = Selectors.getRoot(getState());
            const itemOffset = ((page - 1) * pageSize) % filteredOpportunities.length;

            dispatch(stateController.setState((prevState) => {
                return {
                    ...prevState,
                    currentPage: page,
                    currentItems: filteredOpportunities.slice(itemOffset, itemOffset + pageSize),
                }
            }));

            let message = '';
            switch (paginationState) {
                case PaginationClickState.Number:
                    message = `Moved to Page: ${page}`;
                    break;
                case PaginationClickState.Left:
                    message = `Moved to Previous Page: ${page}`;
                    break;
                case PaginationClickState.Right:
                    message = `Moved to Next Page: ${page}`;
                    break;
            }

            dispatch(userActivityInsert({
                PageName: 'Pitch Opportunities [Pagination]',
                Message: message,
                PageType: PageType.Search
            }))
        }
    }

    public static setPageSize = (page: number, pageSize: number) => {
        return (dispatch, getState: () => AppState) => {
            const { filteredOpportunities } = Selectors.getRoot(getState());
            const itemOffset = ((page - 1) * pageSize) % filteredOpportunities.length;

            dispatch(stateController.setState((draftState) => {
                return {
                    ...draftState,
                    pageSize,
                    currentPage: page,
                    totalPageCount: Math.ceil(filteredOpportunities.length / pageSize),
                    currentItems: filteredOpportunities.slice(itemOffset, itemOffset + pageSize)
                }
            }));

            dispatch(userActivityInsert({
                PageName: 'Pitch Opportunities [Pagination]',
                Message: `Selected ${pageSize} rows`,
                PageType: PageType.Search
            }))
        }
    }

    public static paginationSetState(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ paginationState: value }));
        }
    }

    public static applySorting(sortBy: PitchSortByKeyEnum, column?: string) {
        return (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState({ sortBy }));
            dispatch(Actions.setSorting());

            dispatch(userActivityInsert({
                Message: column,
                PageType: PageType.Search,
                PageName: 'Pitch Opportunities',
            }))
        }
    }

    public static setSorting() {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const selectedPlayerId = FilterSelectors.getRoot(getState()).playerFilterModal.selectedPlayerId;
            const sortBy = Selectors.getSortBy(getState());
            let opportunitiesSorted = [];

            if (selectedPlayerId) {
                dispatch(Actions.setSelectedPlayersRelevance());
            }

            const { filteredOpportunities } = Selectors.getRoot(getState());

            if (sortBy === PitchSortByKeyEnum.MostRecent) {
                opportunitiesSorted = filteredOpportunities.sort((a, b) => {
                    if (a.isDismissed !== b.isDismissed) {
                        return a.isDismissed ? 1 : -1;
                    }
                    return (
                        Date.parse(b.playerAd.updatedAt) -
                        Date.parse(a.playerAd.updatedAt)
                    );
                });
            }
            if (sortBy === PitchSortByKeyEnum.TransferFeeLowToHigh) {
                opportunitiesSorted = filteredOpportunities.sort((a, b) => {
                    if (a.isDismissed !== b.isDismissed) {
                        return a.isDismissed ? 1 : -1;
                    } else {
                        return (
                            b.playerAd.amount.price -
                            a.playerAd.amount.price
                        );
                    }
                })
            }
            if (sortBy === PitchSortByKeyEnum.TransferFeeHighToLow) {
                opportunitiesSorted = filteredOpportunities.sort((a, b) => {
                    if (a.isDismissed !== b.isDismissed) {
                        return a.isDismissed ? 1 : -1;
                    } else {
                        return (
                            a.playerAd.amount.price -
                            b.playerAd.amount.price
                        );
                    }
                });
            }
            if (sortBy === PitchSortByKeyEnum.ByRelevance) {
                opportunitiesSorted = filteredOpportunities.sort((a, b) => {
                    if (a.isDismissed !== b.isDismissed) {
                        return a.isDismissed ? 1 : -1;
                    } else if (a.relevance !== b.relevance) {
                        return b.relevance - a.relevance;
                    } else {
                        return (
                            Date.parse(b.playerAd.updatedAt) -
                            Date.parse(a.playerAd.updatedAt)
                        );
                    }
                });
            }

            dispatch(stateController.setState({
                totalPageCount: Math.ceil(opportunitiesSorted.length / subState.pageSize),
                currentItems: opportunitiesSorted.slice(0, subState.pageSize)
            }));
        }
    }

    public static closePlayerAd = (createdBySquad: number, playerAdId: number) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    isProcessing: true,
                    deletedAdProcessingIds: [...prevState.deletedAdProcessingIds, playerAdId]
                })))

                await TransfersOutService.opportunityDecline(createdBySquad, playerAdId);
                await dispatch(Actions.getInitialData());

            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    isProcessing: false,
                    deletedAdProcessingIds: prevState.deletedAdProcessingIds.filter(item => item !== playerAdId)
                })))
            }
        }
    }

    public static resetPage = () => {
        return (dispatch) => {
            dispatch(stateController.setState((draftState) => {
                return {
                    ...draftState,
                    currentPage: 1,
                }
            }))
        }
    }

    public static applyFilters() {
        return (dispatch, getState: () => AppState) => {
            const { pageSize, opportunities } = Selectors.getRoot(getState());
            const { sortToBuy, sortToLoan, positionFilter, transferTypeBudgetModal, playerFilterModal } = FilterSelectors.getRoot(getState());
            const positionName = FilterSelectors.getPositionFilterName(getState());
            const { transferFee, annualGrossSalary, loanFee } = transferTypeBudgetModal;
            const { selectedPlayerId } = playerFilterModal;
            let filteredOpportunities = [];

            filteredOpportunities = opportunities.filter(opportunity => {
                const matchesToBuy = sortToBuy && opportunity.playerAd.type === sortToBuy;
                const matchesToLoan = sortToLoan && opportunity.playerAd.type === sortToLoan;
                const matchesType = (sortToBuy && matchesToBuy) || (sortToLoan && matchesToLoan) || (!sortToBuy && !sortToLoan);

                let matchesPosition = true;

                if (positionFilter === SearchPlayerPositionEnum.CentreBack_LeftFooted) {
                    matchesPosition = opportunity.playerAd.positionName === positionFullNames.CB && opportunity.playerAd.isLeftFooted === true;
                } else if (positionFilter === SearchPlayerPositionEnum.CentreBack_All) {
                    matchesPosition = opportunity.playerAd.positionName === positionFullNames.CB;
                } else {
                    matchesPosition = !positionFilter || opportunity.playerAd.positionName === positionName;
                }

                const matchesTransferFee =
                    opportunity.playerAd?.amount?.price >= transferFee.min &&
                    opportunity.playerAd?.amount?.price <= transferFee.max;

                const matchesAnnualGrossSalary = !opportunity.playerAd?.grossSalary?.price ||
                    (opportunity.playerAd?.grossSalary?.price >= annualGrossSalary.min &&
                        opportunity.playerAd?.grossSalary?.price <= annualGrossSalary.max);

                const matchesLoanFee = opportunity.playerAd?.amount?.price >= loanFee.min &&
                    opportunity.playerAd?.amount?.price <= loanFee.max;

                const matchesPlayer = !selectedPlayerId ||
                    (
                        (opportunity?.matchingPlayer?.player?.id === selectedPlayerId &&
                            opportunity?.matchingPlayer?.isRelevant &&
                            !opportunity?.matchingPlayer?.isPitched) ||
                        (opportunity?.playersWithRelevance?.some(player =>
                            player?.player?.id === selectedPlayerId &&
                            player?.isRelevant &&
                            !player?.isPitched))
                    );

                return matchesType && matchesPosition && matchesTransferFee && matchesAnnualGrossSalary && matchesLoanFee && matchesPlayer;
            });

            // if (selectedPlayer) {
            //     filteredOpportunities.sort((a, b) => {
            //         if (a.isDismissed !== b.isDismissed) {
            //             return a.isDismissed ? 1 : -1;
            //         } else if (a.relevance !== b.relevance) {
            //             return b.relevance - a.relevance;
            //         } else {
            //             return (
            //                 Date.parse(b.playerAd.updatedAt) -
            //                 Date.parse(a.playerAd.updatedAt)
            //             );
            //         }
            //     });
            // }

            dispatch(stateController.setState({
                filteredOpportunities: filteredOpportunities,
                totalResultCount: filteredOpportunities.length,
                totalPageCount: Math.ceil(filteredOpportunities.length / pageSize),
                currentItems: filteredOpportunities.slice(0, pageSize),
            }));

            dispatch(Actions.setSorting());
        };
    };

    public static setRelevancePlayer(id: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ relevancePlayerId: id }));
        }
    }

    public static setSelectedPlayer(playerId: number, adId: number) {
        return (dispatch, getState: () => AppState) => {
            const currentAds = getState().clubPitchOpportunities.pitchOpportunities.currentItems.map(item => {
                if (item.playerAd.id === adId) {
                    item.selectedPlayerId = playerId;
                    return item
                };
                return item;
            });

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                currentItems: currentAds,
            })));
        }
    }

    public static openPitchPlayerPage(opportunity: OpportunityItemWithRelevance) {
        return (dispatch, getState: () => AppState) => {
            const { selectedPlayerId } = FilterSelectors.getRoot(getState()).playerFilterModal;
            const playerId = selectedPlayerId ? selectedPlayerId : opportunity.selectedPlayerId

            historyAccessor.replace(`${userPaths.pitchPage}?playerId=${playerId}&adId=${opportunity.playerAd.id}`);
        }
    }

}

class Selectors {
    public static getRoot = (state: AppState) => state.clubPitchOpportunities.pitchOpportunities;
    public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
    public static getOpportunities = (state: AppState) => Selectors.getRoot(state).opportunities;
    public static currentItems = (state: AppState) => Selectors.getRoot(state).currentItems;
    public static getSortBy = (state: AppState) => Selectors.getRoot(state).sortBy;
    public static getPlayers = (state: AppState) => Selectors.getRoot(state).players;
    public static getRelevancePlayerId = (state: AppState) => Selectors.getRoot(state).relevancePlayerId;

    public static getCurrentPage = (state: AppState) => Selectors.getRoot(state).currentPage
    public static getPageSize = (state: AppState) => Selectors.getRoot(state).pageSize;
    public static getTotalRowCount = (state: AppState) => Selectors.getRoot(state).totalResultCount;
    public static getTotalPageCount = (state: AppState) => Selectors.getRoot(state).totalPageCount;
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    ClubPitchOpportunitiesState as State,
    Actions as Actions,
    stateController as Controller
};
