import TransfersOutInsightsService from 'api/transfers-out/insights/transfers-out-insights.service';
import { InsightsHighlightsType } from 'api/transfers-out/models/transfers-out-insights.model';
import historyAccessor from 'history-accessor';
import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';

export enum PaginationClickState {
    Number = 1,
    Left = 2,
    Right = 3,
}

class TransfersOutHighlightsState {
    highlights: InsightsHighlightsType[];
    isLoading: boolean;
    pageSize: number;
    currentPage: number;
    totalResultCount: number;
    totalPageCount: number;
    paginationState: PaginationClickState;
}

const defaultState: TransfersOutHighlightsState = {
    highlights: [],
    isLoading: false,
    pageSize: 10,
    currentPage: 1,
    totalResultCount: 0,
    totalPageCount: 0,
    paginationState: PaginationClickState.Number,
};

const stateController = new StateController<TransfersOutHighlightsState>(
    'SQUAD_TRANSFER_OUT/HIGHLIGHTS',
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        };
    }

    public static getHighlights() {
        return async (dispatch) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                const { highlights } = await TransfersOutInsightsService.getAllHighlights();
                dispatch(
                    stateController.setState({
                        highlights,
                    })
                );
                dispatch(Actions.initPagination());
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static onBackButtonClick() {
        return () => {
            historyAccessor.goBack();
        };
    }

    public static initPagination() {
        return (dispatch, getState: () => AppState) => {
            const pageSize = Selectors.pageSize(getState());
            const highlightsCount = Selectors.highlightsCount(getState());

            dispatch(
                stateController.setState({
                    pageSize,
                    currentPage: 1,
                    totalResultCount: highlightsCount,
                    totalPageCount: Math.ceil(highlightsCount / pageSize),
                })
            );
        };
    }

    public static setPage = (page: number) => {
        return (dispatch) => {
            dispatch(
                stateController.setState({
                    currentPage: page,
                })
            );
        };
    };

    public static setPageSize = (page: number, pageSize: number) => {
        return (dispatch, getState: () => AppState) => {
            const highlightsCount = Selectors.highlightsCount(getState());

            dispatch(
                stateController.setState((draftState) => {
                    return {
                        ...draftState,
                        pageSize,
                        currentPage: page,
                        totalPageCount: Math.ceil(highlightsCount / pageSize),
                    };
                })
            );
        };
    };

    public static paginationSetState(value: number) {
        return (dispatch) => {
            dispatch(stateController.setState({ paginationState: value }));
        };
    }
}

class Selectors {
    private static getRoot = (state: AppState) => state.transfersOut.highlights;
    private static allHighlights = (state: AppState) => Selectors.getRoot(state).highlights;
    public static paginatedHighlights = (state: AppState) => {
        const highlights = Selectors.allHighlights(state);
        const currentPage = Selectors.currentPage(state);
        const pageSize = Selectors.pageSize(state);

        const itemOffset = ((currentPage - 1) * pageSize) % highlights.length;

        return highlights.slice(itemOffset, itemOffset + pageSize);
    };
    public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
    public static isHighlightsEmpty = (state: AppState) => Selectors.highlightsCount(state) === 0;
    public static highlightsCount = (state: AppState) => Selectors.allHighlights(state).length;
    public static pageSize = (state: AppState) => Selectors.getRoot(state).pageSize;
    public static currentPage = (state: AppState) => Selectors.getRoot(state).currentPage;
    public static totalRowCount = (state: AppState) => Selectors.getRoot(state).totalResultCount;
    public static totalPageCount = (state: AppState) => Selectors.getRoot(state).totalPageCount;
}

const reducer = stateController.getReducer();

export {
    Selectors,
    Actions,
    reducer as Reducer,
    TransfersOutHighlightsState as State,
    stateController as Controller,
};
