import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import historyAccessor from 'history-accessor';
import { userPaths } from 'routes/paths';
import {
    Actions as CommonActions,
    Selectors as CommonSelectors,
} from 'pages/club/transfers-in/redux/transfers-in-common.controller';

class TransfersInAllPitchesState {
    isLoading: boolean;
}

const defaultState: TransfersInAllPitchesState = {
    isLoading: false,
};

const stateController = new StateController<TransfersInAllPitchesState>(
    'SQUAD_TRANSFER_IN/ALL_PITCHES',
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        };
    }
    public static onBackButtonClick() {
        return (dispatch) => {};
    }

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                // const pitches = await TransfersOutService.getSavedPitches();
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersIn.allPitches;
    public static getIsLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    TransfersInAllPitchesState as State,
    Actions as Actions,
    stateController as Controller,
};
