import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import historyAccessor from 'history-accessor';
import { userPaths } from 'routes/paths';
import {
    Actions as CommonActions,
    Selectors as CommonSelectors,
} from 'pages/club/transfers-in/redux/transfers-in-common.controller';
import TransfersInService from 'api/transfers-in/transfers-in.service';
import { MyRequirementsPosition } from 'api/transfers-in/models/transfers-in-requirements';
import * as CreateRequirementController from 'pages/club/transfers-in/redux/transfers-in-create-new-requirement.controller';

class TransfersInRequirementsState {
    isLoading: boolean;
    myRequirements: MyRequirementsPosition[];
    processingRequirement: MyRequirementsPosition;
}

const defaultState: TransfersInRequirementsState = {
    isLoading: false,
    myRequirements: [],
    processingRequirement: null,
};

const stateController = new StateController<TransfersInRequirementsState>(
    'SQUAD_TRANSFER_IN/REQUIREMENTS',
    defaultState,
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        };
    }

    public static getRequirementsData() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                const { items } = await TransfersInService.getAdsPitches();

                if (items) {
                    dispatch(stateController.setState({ myRequirements: items }));
                }
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static openCreateRequirementModal(requirement: MyRequirementsPosition) {
        return (dispatch) => {
            dispatch(stateController.setState({ processingRequirement: requirement }));
            dispatch(CreateRequirementController.Actions.openModal());
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersIn.requirements;
    public static getMyRequirements = (state: AppState) => Selectors.getRoot(state).myRequirements;
    public static getIsLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    TransfersInRequirementsState as State,
    Actions as Actions,
    stateController as Controller,
};
