import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import {
    GroupedSquadOpportunities,
    OpportunityItem,
    RecentPlayers,
} from 'api/transfers-out/models/transfers-out';
import TransfersOutService from 'api/transfers-out/transfers-out.service';
import historyAccessor from 'history-accessor';
import { userPaths } from 'routes/paths';
import { getAuth, getAuthSquadId, isClubCanPitchPlayersEnabled } from 'store/auth/authReducer';
import { SubscriptionPlanEnum } from 'constants/enums';
import { Actions as TransfersOutInsightsController } from './transfers-out-insights.controller';
import * as TransfersOutMySquadController from 'pages/club/transfers-out/redux/transfers-out-my-squad-controller';
import {
    Actions as PlayerDetailsController,
    TableKey,
} from 'pages/club/transfers-out/redux/transfer-out-player-detail.controller';
import { Actions as TransfersOutInsightsTabController } from 'pages/club/transfers-out/redux/transfers-out-insights-tab.controller';
import { InsightsFilterTypesEnum } from 'api/transfers-out/models/transfers-out-insights.model';

class TransfersOutRibbonState {
    updatesCount: number;
    recentPlayers: RecentPlayers[];
    opportunities: OpportunityItem[];
    newOpportunitiesCounter: number;
    insightPageRestrictedAccessModal: {
        isModalOpen: boolean;
    };
}

const defaultState: TransfersOutRibbonState = {
    updatesCount: 0,
    recentPlayers: [],
    opportunities: [],
    newOpportunitiesCounter: 0,
    insightPageRestrictedAccessModal: {
        isModalOpen: false,
    },
};

const stateController = new StateController<TransfersOutRibbonState>(
    'SQUAD_TRANSFER_OUT/RIBBON',
    defaultState,
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        };
    }

    public static getRibbonData() {
        return async (dispatch, getState: () => AppState) => {
            await dispatch(Actions.getOpportunities());
            await dispatch(Actions.getRecentPlayers());
        };
    }

    public static getOpportunities() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = getState().transfersOut.common;
                dispatch(
                    stateController.setState({
                        opportunities: data.opportunities,
                        newOpportunitiesCounter: data.newOpportunitiesCounter,
                    }),
                );
            } catch (error) {
                console.error(error);
            }
        };
    }

    public static getRecentPlayers() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const currentSquadId = getAuthSquadId(getState());
                const opportunities = Selectors.getOpportunities(getState());
                const groupedSquadOpportunities = Helpers.groupByPlayerId(
                    opportunities,
                    currentSquadId,
                );

                const { recentPlayers } = await TransfersOutService.getRecentPitches();
                const recentPlayersExtended = recentPlayers.map((player) => ({
                    ...player,
                    matches: groupedSquadOpportunities[player.playerId]?.length ?? 0,
                }));
                dispatch(stateController.setState({ recentPlayers: recentPlayersExtended }));
            } catch (error) {
                console.error(error);
            }
        };
    }

    public static openPitchOpportunitiesPage() {
        return (dispatch, getState: () => AppState) => {
            historyAccessor.push(userPaths.pitchOpportunities);
        };
    }

    public static openInsightsPage() {
        return (dispatch, getState: () => AppState) => {
            if (Selectors.isUserHasLimitedAccessToInsights(getState())) {
                dispatch(TransfersOutInsightsController.openInsightsPageRestrictedAccessModal());
            } else {
                historyAccessor.push(userPaths.transfersOutInsights);
            }
        };
    }

    public static openPlayerDetailPage(playerId: number) {
        return (dispatch, getState: () => AppState) => {
            const substate = TransfersOutMySquadController.Selectors.getRoot(getState()).mySquad;
            const player = substate.find((player) => player.id === playerId);

            dispatch(TransfersOutMySquadController.Actions.setActivePlayer(player));
            historyAccessor.push(`${userPaths.transfersOutPlayer}/${playerId}`);
        };
    }

    public static openPlayerInsightsPageWithFilter(
        playerId: number,
        filter: InsightsFilterTypesEnum.ActionableItems | InsightsFilterTypesEnum.SavedPitches,
    ) {
        return (dispatch) => {
            dispatch(Actions.openPlayerDetailPage(playerId));
            dispatch(PlayerDetailsController.setActiveTab(TableKey.Insights));
            dispatch(TransfersOutInsightsTabController.setPlayerInsightsFilter(filter));
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersOut.ribbon;
    public static recentPlayers = (state: AppState) => Selectors.getRoot(state).recentPlayers;
    public static getOpportunities = (state: AppState) => Selectors.getRoot(state).opportunities;
    public static newOpportunitiesCounter = (state: AppState) =>
        Selectors.getRoot(state).newOpportunitiesCounter;
    public static updatesCount = (state: AppState) => Selectors.getRoot(state).updatesCount;
    public static isAllowedToPitchPlayers = (state: AppState) =>
        isClubCanPitchPlayersEnabled(state);
    public static isUserHasLimitedAccessToInsights = (state: AppState) => {
        const userSubscriptionPlan = getAuth(state).subscriptionPlan;

        return (
            userSubscriptionPlan !== null &&
            [
                SubscriptionPlanEnum.EntryPackageLatam,
                SubscriptionPlanEnum.UKStarter,
                SubscriptionPlanEnum.EUFreemium,
                SubscriptionPlanEnum.UKFreemium,
            ].includes(userSubscriptionPlan)
        );
    };
}

class Helpers {
    public static groupByPlayerId(inputObj: OpportunityItem[], squadId?: number) {
        let outputObj: GroupedSquadOpportunities = {};

        for (let index = 0; index < inputObj.length; index++) {
            const element = inputObj[index];
            if (element.playerAd.createdBySquad.id === squadId) {
                continue;
            }

            element.playersWithRelevance.forEach((playerRelevance) => {
                const playerId = playerRelevance.player.id;

                if (!outputObj[playerId]) {
                    outputObj[playerId] = [];
                }

                outputObj[playerId].push(element);
            });
        }

        return outputObj;
    }
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    TransfersOutRibbonState as State,
    Actions as Actions,
    stateController as Controller,
};
