import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import historyAccessor from 'history-accessor';
import { userPaths } from 'routes/paths';
import {
    Actions as CommonActions,
    Selectors as CommonSelectors,
} from 'pages/club/transfers-in/redux/transfers-in-common.controller';
import TransfersInService from 'api/transfers-in/transfers-in.service';
import { RibbonData } from 'api/transfers-in/models/transfers-in-top-ribbon';

class TransfersInRequirementsState {
    isLoading: boolean;
    ribbon: RibbonData;
}

const defaultState: TransfersInRequirementsState = {
    isLoading: false,
    ribbon: null,
};

const stateController = new StateController<TransfersInRequirementsState>(
    'SQUAD_TRANSFER_IN/TOP_RIBBON',
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        };
    }

    public static getRibbonData() {
        return async (dispatch, getState: () => AppState) => {
            // dispatch(stateController.setState({ isLoading: true }));
            try {
                const data = await TransfersInService.getRibbonData();

                if (data) {
                    dispatch(stateController.setState({ ribbon: data }));
                }
            } catch (error) {
                console.error(error);
            } finally {
                // dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static openRibbonRelatedPage(path: string) {
        return (dispatch, getState: () => AppState) => {
            historyAccessor.push(path);
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersIn.ribbon;
    public static getRibbonInitData = (state: AppState) => Selectors.getRoot(state).ribbon;
    public static getIsLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    TransfersInRequirementsState as State,
    Actions as Actions,
    stateController as Controller,
};
