import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import { getAuth } from 'store/auth/authReducer';
import TransfersOutInsightsService from 'api/transfers-out/insights/transfers-out-insights.service';
import { Permissions as PermissionsService } from 'api/permission/permission.servise';
import {
    InsightsHighlightsType,
    InsightsRecentPitchType,
} from 'api/transfers-out/models/transfers-out-insights.model';
import historyAccessor from 'history-accessor';
import { userPaths } from 'routes/paths';

class TransfersOutInsightsState {
    isLoading: boolean;
    resentPitches: InsightsRecentPitchType[];
    highlights: InsightsHighlightsType[];
    isInsightPageRestrictedAccessModalOpen: boolean;
    permissionRequestState: 'default' | 'loading' | 'success';
}

const defaultState: TransfersOutInsightsState = {
    isLoading: false,
    resentPitches: [],
    highlights: [],
    isInsightPageRestrictedAccessModalOpen: false,
    permissionRequestState: 'default',
};

const stateController = new StateController<TransfersOutInsightsState>(
    'SQUAD_TRANSFER_OUT/INSIGHTS',
    defaultState,
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        };
    }

    public static getInsightsData() {
        return async (dispatch) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                await dispatch(Actions.getResentPlayers());
                await dispatch(Actions.getHighlights());
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static getResentPlayers() {
        return async (dispatch) => {
            try {
                const { recentPlayers } = await TransfersOutInsightsService.getRecentPitches();
                dispatch(
                    stateController.setState({
                        resentPitches: recentPlayers,
                    }),
                );
            } catch (error) {
                console.error(error);
            }
        };
    }

    public static getHighlights() {
        return async (dispatch) => {
            try {
                const { highlights } = await TransfersOutInsightsService.getHighlightsWithCount();
                dispatch(
                    stateController.setState({
                        highlights: highlights,
                    }),
                );
            } catch (error) {
                console.error(error);
            }
        };
    }

    public static onBackButtonClick() {
        return () => {
            historyAccessor.goBack();
        };
    }

    public static openInsightsPageRestrictedAccessModal() {
        return (dispatch, _) => {
            dispatch(
                stateController.setState({
                    isInsightPageRestrictedAccessModalOpen: true,
                }),
            );
        };
    }

    public static closeInsightsPageRestrictedAccessModal() {
        return (dispatch, _) => {
            dispatch(
                stateController.setState({
                    isInsightPageRestrictedAccessModalOpen: false,
                }),
            );
        };
    }

    public static sendPermissionRequest() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState({
                    permissionRequestState: 'loading',
                }),
            );

            let auth = getAuth(getState());
            const { squadId, userId } = auth;

            PermissionsService.sendPermissionRequest(
                squadId,
                userId,
                'Get in touch',
                'Transfers Out',
            )
                .then(() => {
                    dispatch(
                        stateController.setState({
                            permissionRequestState: 'success',
                        }),
                    );
                })
                .catch(() => {
                    stateController.setState({
                        permissionRequestState: 'default',
                    });
                });
        };
    }

    public static openHighlightsPage() {
        return () => {
            historyAccessor.push(userPaths.transfersOutHighlights);
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersOut.insights;
    public static getRecentInsightsPitches = (state: AppState) =>
        Selectors.getRoot(state).resentPitches;
    public static getHighlightsList = (state: AppState) => Selectors.getRoot(state).highlights;
    public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
    public static isInsightPageRestrictedAccessModalOpen = (state: AppState) =>
        Selectors.getRoot(state).isInsightPageRestrictedAccessModalOpen;
    public static isPermissionRequestSent = (state: AppState) =>
        Selectors.getRoot(state).permissionRequestState === 'success';
    public static isPermissionRequestSending = (state: AppState) =>
        Selectors.getRoot(state).permissionRequestState === 'loading';
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    Selectors as Selectors,
    TransfersOutInsightsState as State,
    Actions as Actions,
    stateController as Controller,
};
