import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import historyAccessor from 'history-accessor';
import { openPlusPitchCreateModal } from 'app/pitch-plus/create-flow/store/pitch-plus-create.service';
import { plusPitchesFetch } from 'pages/Pitch/tab-plus-pitch/store/actions';
import { getClubUserPermissions } from 'store/userPreference/userPreference.reducer';

export enum CreateActionTypesEnum {
    PitchPlayer = 1,
    PlusPitchPlayer = 2,
    SuperPitchPlayer = 3,
    PostRequirement = 4,
    ArrangeFriendly = 5,
}
class LayoutState {
    isSideBarCollapsed: boolean;
    isHamburgerMenuOpen: boolean | null;
    isLoginDropdownOpen: boolean;
    isCreateModalOpen: boolean;
}

const defaultState: LayoutState = {
    isSideBarCollapsed: false,
    isHamburgerMenuOpen: null,
    isLoginDropdownOpen: false,
    isCreateModalOpen: false,
};

const stateController = new StateController<LayoutState>('LAYOUT', defaultState);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        };
    }

    public static setSidebarCollapsed() {
        return (dispatch, getState: () => AppState) => {
            const isCollapsed = getState().layout.isSideBarCollapsed;
            dispatch(stateController.setState({ isSideBarCollapsed: !isCollapsed }));
        };
    }

    public static setHamburgerMenu() {
        return (dispatch, getState: () => AppState) => {
            const isOpen = getState().layout.isHamburgerMenuOpen;
            dispatch(stateController.setState({ isHamburgerMenuOpen: !isOpen }));
        };
    }

    public static toggleLoginDropdown() {
        return (dispatch, getState: () => AppState) => {
            const visible = Selectors.getLoginDropdownVisibility(getState());
            const isHamburgerMenuOpen = getState().layout.isHamburgerMenuOpen;

            if (isHamburgerMenuOpen) {
                dispatch(stateController.setState({ isHamburgerMenuOpen: null }));
            }

            dispatch(stateController.setState({ isLoginDropdownOpen: !visible }));
        };
    }

    public static toggleCreatePopup() {
        return (dispatch, getState: () => AppState) => {
            const visible = Selectors.getCreatePopupVisibility(getState());
            dispatch(stateController.setState({ isCreateModalOpen: !visible }));
        };
    }

    public static openSelectedModal(type: CreateActionTypesEnum) {
        return (dispatch, getState: () => AppState) => {
            const availiblePlusPitces = Selectors.getPlusPitchesCount(getState());
            if (type === CreateActionTypesEnum.PlusPitchPlayer && availiblePlusPitces > 0) {
                dispatch(Actions.openCreatePlusPitchModal());
            }
            dispatch(Actions.toggleCreatePopup());
        };
    }

    public static openCreatePlusPitchModal() {
        return async (dispatch) => {
            dispatch(plusPitchesFetch());
            dispatch(openPlusPitchCreateModal());
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.layout;
    public static getLoginDropdownVisibility = (state: AppState) =>
        Selectors.getRoot(state).isLoginDropdownOpen;
    public static getCreatePopupVisibility = (state: AppState) =>
        Selectors.getRoot(state).isCreateModalOpen;
    public static getIsCollapsed = (state: AppState) => Selectors.getRoot(state).isSideBarCollapsed;
    public static getPlusPitchesCount = (state: AppState) =>
        getClubUserPermissions(state)?.allowedPlusPitchesCount;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    LayoutState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller,
};
