import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { PlayerStatisticService } from 'api/player/player-statistic-service';
import { Player } from 'api/player/model/player';
import {
    getAuth,
    hasAgentFeature,
    getAgentsVisibility,
    getAuthCurrency,
    isEntryPackageSubscription,
} from 'store/auth/authReducer';
import { setupAgencyFeature } from 'store/auth/auth.actions';
import { PlayerService } from 'api/player/player-service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import CustomSignInService from 'api/custom-sign-in/custom-sign-in.service';
import historyAccessor from 'history-accessor';
import { userPaths } from 'routes/paths';
import { IdName } from 'api/core/id-name';
import { getCancelTokenSource } from 'axios-config';
import {
    MyRequirementsPosition,
    PositionRequirementInitialInfoResponse,
    PositionRequirementsWithCountersResponse,
    CreatePositionRequirementsRequest,
    PositionRequirementType,
} from 'api/transfers-in/models/transfers-in-requirements';
import { GeneralPositionEnum } from 'api/core/general-position.model';
import AgencyOnboardingService from 'api/agency/club/onboarding/onboarding.servise';
import { getPositionCodeById } from 'utils/position-helper';
import TransfersInService from 'api/transfers-in/transfers-in.service';
import { Actions as CommonControllerActions } from 'pages/club/transfers-in/redux/transfers-in-common.controller';
import { Actions as PerformanceActions } from 'pages/landing-page/redux/performance-data.controller';

export const ageRule = { min: 16, max: 40 };
export const heightRule = { min: 150, max: 210 };

export class CreatePositionRequirementModel {
    toLoan: boolean;
    toBuy: boolean;
    loan?: number;
    buy?: number;
    grossSalary?: number;
    age: { min: number; max: number };
    height: number;
    isLeftFooted: boolean;
    isAgencyRecommended: boolean;
    preferredPlayerArea: IdName;
    preferredPlayingStyle: IdName;
    isAnonymiseRequirement: boolean;
}

class CreatePlayerRequirementState {
    isModalVisible: boolean;
    requirement: MyRequirementsPosition;
    positionRequirement: CreatePositionRequirementModel;
    currentStep: number;
    // playerSuggestions: Player[];
    shortPlayerSuggestions: Player[];
    processingSuggestions: number[];
    processing: boolean;
    positionRequirementInitialInfo: PositionRequirementInitialInfoResponse;
    existPositionsRequirements: PositionRequirementsWithCountersResponse[];
    isLoading: boolean;
    nationalityPreferenceAreas: IdName[];
    preferredPlayingStyles: IdName[];
    isDeleteModalOpen: boolean;
    processingPositionRequirements: number[];
}

const defaultState: CreatePlayerRequirementState = {
    isModalVisible: false,
    positionRequirement: null,
    requirement: null,
    currentStep: 1,
    // playerSuggestions: [],
    shortPlayerSuggestions: [],
    processing: false,
    processingSuggestions: [],
    positionRequirementInitialInfo: null,
    existPositionsRequirements: [],
    isLoading: false,
    nationalityPreferenceAreas: [],
    preferredPlayingStyles: [],
    isDeleteModalOpen: false,
    processingPositionRequirements: [],
};

const stateController = new StateController<CreatePlayerRequirementState>(
    'SQUAD_TRANSFER_IN/CREATE_PLAYER_REQUIREMENT',
    defaultState,
);

class Actions {
    public static token = null;

    public static disposeModalState() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        };
    }

    public static initModalData() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState({
                    isLoading: true,
                }),
            );
            try {
                const selectedRequirement =
                    getState().transfersIn.requirements.processingRequirement;
                dispatch(
                    stateController.setState({
                        requirement: selectedRequirement,
                    }),
                );
                await dispatch(Actions.loadPositionRequirementsInitialInfo());

                if (selectedRequirement.isActive) {
                    await dispatch(Actions.loadAllPositionsRequirements());
                    dispatch(Actions.setInitialExistingRequirementState(selectedRequirement));
                }
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(
                    stateController.setState({
                        isLoading: false,
                    }),
                );
            }
        };
    }

    public static openModal() {
        return (dispatch) => {
            dispatch(
                stateController.setState({
                    isModalVisible: true,
                }),
            );
        };
    }

    public static closeModal() {
        return async (dispatch) => {
            dispatch(stateController.setState({ isModalVisible: false }));
        };
    }

    public static loadAllPositionsRequirements() {
        return async (dispatch, getState: () => AppState) => {
            Actions.token = getCancelTokenSource();

            let items = await TransfersInService.getAllRequirementsWithCounters(
                Actions.token.token,
            );

            if (items) {
                dispatch(stateController.setState({ existPositionsRequirements: items }));
            }
        };
    }

    public static setInitialExistingRequirementState(requirement: MyRequirementsPosition) {
        return async (dispatch, getState: () => AppState) => {
            const positionCode = getPositionCodeById(requirement.generalPositionId);
            const subState = Selectors.getExistPositionRequirements(getState()).filter(
                (item) => item.ad.positionCode === positionCode,
            );
            const processingIds = subState.map((item) => item.ad.id);
            dispatch(stateController.setState({ processingPositionRequirements: processingIds }));

            const areas = Selectors.getPreferredPlayerAreasList(getState());
            const styles = Selectors.getPreferredPlayingStylesList(getState());

            let obj: CreatePositionRequirementModel = {
                toLoan: false,
                toBuy: false,
                loan: null,
                buy: null,
                grossSalary: null,
                age: {
                    min: null,
                    max: null,
                },
                height: null,
                isLeftFooted: false,
                isAgencyRecommended: false,
                preferredPlayerArea: null,
                preferredPlayingStyle: null,
                isAnonymiseRequirement: false,
            };

            subState.forEach((item) => {
                if (item.ad.type === PositionRequirementType.Buy) {
                    obj.toBuy = true;
                    obj.buy = item.ad.amount.price;
                } else if (item.ad.type === PositionRequirementType.Loan) {
                    obj.toLoan = true;
                    obj.loan = item.ad.amount.price;
                }

                obj.grossSalary = item.ad.grossSalary?.price;
                obj.age.min = item.ad.fromAge;
                obj.age.max = item.ad.toAge;
                obj.height = item.ad.minHeight;
                obj.isLeftFooted = item.ad.isLeftFooted;
                obj.preferredPlayerArea = areas.find(
                    (x) => x.id === item.ad.preferredNationalityAreaId,
                );
                if (requirement.generalPositionId !== GeneralPositionEnum.Goalkeeper) {
                    obj.preferredPlayingStyle = styles.find((x) => x.id === item.ad.playingStyleId);
                }
                obj.isAnonymiseRequirement = item.ad.isAnonymised;
            });

            dispatch(stateController.setState({ positionRequirement: obj }));
        };
    }

    public static nextStep() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState({
                    currentStep: Selectors.getCurrentStep(getState()) + 1,
                }),
            );
        };
    }

    public static prevStep() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState({
                    currentStep: Selectors.getCurrentStep(getState()) - 1,
                }),
            );
        };
    }

    public static goToFinalStep() {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    currentStep: 3,
                })),
            );
        };
    }

    public static createNewRequirements() {
        return async (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRequirement(getState());
            if (!!subState.isLeftFooted) {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        positionRequirement: {
                            ...prevState.positionRequirement,
                            isLeftFooted: subState?.isLeftFooted,
                        },
                    })),
                );
            }

            const requirement = Selectors.getPositionRequirement(getState());

            if (
                Selectors.getAgentsVisibility(getState()) &&
                !Selectors.getHasAgentFeature(getState()) &&
                requirement.isAgencyRecommended
            ) {
                dispatch(stateController.setState({ processing: true }));

                await AgencyOnboardingService.setUpAgencyFeatures();
                dispatch(setupAgencyFeature());
                dispatch(stateController.setState({ processing: false }));
            }

            let request: Array<CreatePositionRequirementsRequest> =
                new Array<CreatePositionRequirementsRequest>();

            if (requirement.toBuy) {
                request.push({
                    toAge: requirement.age ? requirement.age.max : null,
                    fromAge: requirement.age ? requirement.age.min : null,
                    minHeight: requirement.height,
                    type: PositionRequirementType.Buy,
                    amount: requirement.buy,
                    positionId: subState?.generalPositionId,
                    currencyId: Selectors.getCurrency(getState())?.id,
                    grossSalary: requirement.grossSalary,
                    isAgencyRecommendation: requirement.isAgencyRecommended,
                    isLeftFooted: requirement.isLeftFooted,
                    preferredNationalityAreaId: requirement.preferredPlayerArea?.id,
                    playingStyleId: requirement.preferredPlayingStyle?.id,
                    isAnonymised: requirement.isAnonymiseRequirement,
                });
            }

            if (requirement.toLoan) {
                request.push({
                    toAge: requirement.age ? requirement.age.max : null,
                    fromAge: requirement.age ? requirement.age.min : null,
                    minHeight: requirement.height,
                    type: PositionRequirementType.Loan,
                    amount: requirement.loan,
                    positionId: subState?.generalPositionId,
                    currencyId: Selectors.getCurrency(getState())?.id,
                    isAgencyRecommendation: requirement.isAgencyRecommended,
                    isLeftFooted: requirement.isLeftFooted,
                    preferredNationalityAreaId: requirement.preferredPlayerArea?.id,
                    playingStyleId: requirement.preferredPlayingStyle?.id,
                    isAnonymised: requirement.isAnonymiseRequirement,
                });
            }

            dispatch(stateController.setState({ processing: true }));

            try {
                let suggestions = [];
                const { clubId, squadId, userId } = getState().auth;

                if (request.length > 0) {
                    const { playerAds } =
                        await TransfersInService.createPositionRequirements(request);

                    const positionRequirementsIds = playerAds.map((x) => x.id);

                    suggestions = await PlayerStatisticService.getRecomendedPlayersByPlayerAdIds(
                        positionRequirementsIds,
                        clubId,
                        squadId,
                        userId,
                    );
                }

                dispatch(
                    stateController.setState({
                        // playerSuggestions: suggestions,
                        shortPlayerSuggestions: suggestions.slice(0, 3),
                        processing: false,
                    }),
                );

                if (request.length == 0) {
                    dispatch(Actions.closeModal());
                } else {
                    dispatch(Actions.nextStep());
                }
            } catch (e) {
                dispatch(stateController.setState({ processing: false }));
                console.error(e);
            } finally {
                dispatch(stateController.setState({ processing: false }));
            }
        };
    }

    public static validateAnonymiseRequirement(toBuy: boolean, buy?: number, grossSalary?: number) {
        return (dispatch, getState: () => AppState) => {
            if (!toBuy) {
                dispatch(Actions.updatePositionRequirement('isAnonymiseRequirement', false));
            }

            if (!!buy || !!grossSalary) {
                const criteria = Selectors.getPlayerAdAnonymisationCriteria(getState());

                if (
                    (buy < criteria.transferFeeThreshold.price &&
                        grossSalary < criteria.grossSalaryThreshold.price) ||
                    (!buy && grossSalary < criteria.grossSalaryThreshold.price) ||
                    (!grossSalary && buy < criteria.transferFeeThreshold.price)
                ) {
                    dispatch(Actions.updatePositionRequirement('isAnonymiseRequirement', false));
                }
            }
        };
    }

    public static onAgePropertyChanged(min: number, max: number) {
        return (dispatch) => {
            const value = min == ageRule.min && max == ageRule.max ? null : { min: min, max: max };
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    positionRequirement: { ...prevState.positionRequirement, age: value },
                })),
            );
        };
    }

    public static onHeightPropertyChanged(val: number) {
        return (dispatch) => {
            const value = val == heightRule.min ? null : val;
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    positionRequirement: { ...prevState.positionRequirement, height: value },
                })),
            );
        };
    }

    public static declareInterest(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            const suggestions = Selectors.getProcessingSuggestions(getState());
            try {
                const { userId, squadId } = getAuth(getState());

                dispatch(
                    stateController.setState({
                        processingSuggestions: [...suggestions, playerId],
                    }),
                );

                await PlayerService.declareInterestSave(playerId, userId, squadId);

                const shortPlayerSuggestions = Selectors.getShortPlayerSuggestions(getState());

                shortPlayerSuggestions.find((i) => i.id === playerId).player.interestWasDeclared =
                    true;

                dispatch(
                    stateController.setState({
                        shortPlayerSuggestions: [...shortPlayerSuggestions],
                    }),
                );

                if (Selectors.getShortPlayerSuggestions(getState()).length === 0) {
                    dispatch(Actions.closeModal());
                }
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(
                    stateController.setState({
                        processingSuggestions: [
                            ...Selectors.getProcessingSuggestions(getState()).filter(
                                (x) => x !== playerId,
                            ),
                        ],
                    }),
                );
            }
        };
    }

    public static notInterestedSuggestion(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                await CustomSignInService.declinePlayer(playerId);

                const shortPlayerSuggestions = Selectors.getShortPlayerSuggestions(getState());
                const suggestionsWithout = [
                    ...shortPlayerSuggestions.filter((x) => x.id != playerId),
                ];
                dispatch(
                    stateController.setState({ shortPlayerSuggestions: [...suggestionsWithout] }),
                );
                if (Selectors.getShortPlayerSuggestions(getState()).length === 0) {
                    dispatch(Actions.closeModal());
                }
            } catch (e) {
                console.error(e);
            }
        };
    }

    public static suggestionsPlayerShortlistToggle(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            const suggestions = Selectors.getShortPlayerSuggestions(getState());
            const objective = suggestions.filter((i) => i.id === playerId)[0];
            if (objective.player.isInShortList) {
                await PlayerService.deleteFromShortList(playerId);
            } else {
                await PlayerService.addToShortList(playerId);
            }

            suggestions.find((item) => item.id === objective.id).player.isInShortList =
                !objective.player.isInShortList;

            dispatch(stateController.setState({ shortPlayerSuggestions: [...suggestions] }));
        };
    }

    public static updatePositionRequirement(key: string, value: number | boolean) {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    positionRequirement: {
                        ...prevState.positionRequirement,
                        [key]: value,
                    },
                })),
            );
        };
    }

    public static onPreferredPlayerAreaChanged(area: IdName) {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    positionRequirement: {
                        ...prevState.positionRequirement,
                        preferredPlayerArea: area,
                    },
                })),
            );
        };
    }

    public static onPreferredPlayingStyleChanged(playingStyle: IdName) {
        return (dispatch) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    positionRequirement: {
                        ...prevState.positionRequirement,
                        preferredPlayingStyle: playingStyle,
                    },
                })),
            );
        };
    }

    public static loadPositionRequirementsInitialInfo() {
        return async (dispatch, getState: () => AppState) => {
            const initialInfo = await TransfersInService.getPositionRequirementsInitialInfo();

            if (!initialInfo) {
                return null;
            }

            dispatch(
                stateController.setState({
                    positionRequirementInitialInfo: initialInfo,
                }),
            );

            const areas = Selectors.getPreferredPlayerAreasList(getState());
            const styles = Selectors.getPreferredPlayingStylesList(getState());

            dispatch(
                stateController.setState({
                    preferredPlayingStyles: styles,
                    nationalityPreferenceAreas: areas,
                }),
            );
        };
    }

    public static onFindOutMoreClick() {
        return (dispatch) => {
            // dispatch(
            //     userActivityInsert({
            //         Message: `Clicked Playing Style`,
            //         PageName: 'Create Player Ad',
            //         PageType: PageType.PlayerAds,
            //     })
            // );
            window.open(
                'https://blog.transferroom.com/transferrooms-playing-styles-explained',
                '_blank',
            );
        };
    }

    public static onPlayingStyleTooltipHover() {
        return async (dispatch) => {
            // dispatch(
            //     userActivityInsert({
            //         Message: `Viewed Playing Style Tooltip`,
            //         PageName: 'Create Player Ad',
            //         PageType: PageType.PlayerAds,
            //     })
            // );
        };
    }

    public static doneCreatePlayerRequirements() {
        return (dispatch, getState: () => AppState) => {
            dispatch(PerformanceActions.completedCreatePlayerAdsRecommendation());
            dispatch(Actions.closeModal());
            historyAccessor.push(userPaths.transfersIn);
            dispatch(CommonControllerActions.initTransfersInData());
        };
    }

    public static openDeletePositionRequirementsModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isDeleteModalOpen: true }));
        };
    }

    public static closeDeletePositionRequirementsModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isDeleteModalOpen: false }));
        };
    }
    public static removePositionRequirements() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isDeleteModalOpen: true }));
            await dispatch(Actions.deletePositionRequirements());
        };
    }

    public static deletePositionRequirements() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ processing: true }));
            try {
                const ids = Selectors.getRoot(getState()).processingPositionRequirements;

                ids.forEach((id) => {
                    dispatch(Actions.removeRequirement(id));
                });
            } catch (e) {
                console.log(e);
            } finally {
                setTimeout(() => {
                    dispatch(stateController.setState({ processing: false }));
                    dispatch(CommonControllerActions.initTransfersInData());
                }, 500);
            }
        };
    }

    public static removeRequirement(id: number) {
        return async () => {
            await TransfersInService.deletePositionRequirements(id);
        };
    }

    public static openAllPitchesPage() {
        return (dispatch, getState: () => AppState) => {
            historyAccessor.push(userPaths.transfersInAllPitches);
            dispatch(Actions.closeModal());
        };
    }
}
class Selectors {
    public static getRoot = (state: AppState) => state.transfersIn.createRequirement;
    public static getRequirement = (state: AppState) => Selectors.getRoot(state).requirement;
    public static getPositionRequirement = (state: AppState) =>
        Selectors.getRoot(state).positionRequirement;
    public static getModalVisibility = (state: AppState) => Selectors.getRoot(state).isModalVisible;
    public static getDeleteModalVisibility = (state: AppState) =>
        Selectors.getRoot(state).isDeleteModalOpen;
    public static getIsLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).currentStep;
    public static getIsStepProcessing = (state: AppState) => Selectors.getRoot(state).processing;
    public static getRequirementInitialInfo = (state: AppState) =>
        Selectors.getRoot(state).positionRequirementInitialInfo;
    public static getPlayerAdAnonymisationCriteria = (state: AppState) =>
        Selectors.getRequirementInitialInfo(state).anonymisationCriteria;
    public static getExistPositionRequirements = (state: AppState) =>
        Selectors.getRoot(state).existPositionsRequirements;

    public static getPreferredPlayerAreasList = (state: AppState) => {
        const info = Selectors.getRequirementInitialInfo(state);

        let areas = [];
        if (!info) {
            return null;
        } else {
            areas = [
                {
                    id: null,
                    name: 'Global',
                    shortName: null,
                },
                { ...info.preferredRegionArea },
                { ...info.preferredNationalityArea },
            ];
        }

        return areas;
    };

    public static getPreferredPlayingStylesList = (state: AppState) => {
        const positionId = Selectors.getRequirement(state)?.generalPositionId;
        const positions = Selectors.getRequirementInitialInfo(state)?.positionStyles;

        if (!positions || positionId === GeneralPositionEnum.Goalkeeper) {
            return null;
        }

        const styles = positions.find((item) => item.positionId === positionId).playingStyles;

        return [
            {
                id: null,
                name: 'None',
                shortName: null,
            },
            ...styles,
        ];
    };
    // public static getPlayerSuggestions = (state: AppState) =>
    //     Selectors.getRoot(state).playerSuggestions;
    public static getShortPlayerSuggestions = (state: AppState) =>
        Selectors.getRoot(state).shortPlayerSuggestions;
    public static getProcessingSuggestions = (state: AppState) =>
        Selectors.getRoot(state).processingSuggestions;
    public static getCreatedRequirementsCount = (state: AppState) => {
        const requirement = Selectors.getPositionRequirement(state);

        let count = 0;

        if (requirement?.toLoan) count++;
        if (requirement?.toBuy) count++;

        return count;
    };
    public static getCurrency = (state: AppState) => getAuthCurrency(state);
    public static isEntryPackageSubscription = (state: AppState) =>
        isEntryPackageSubscription(state);
    public static getHasAgentFeature = (state: AppState) => hasAgentFeature(state);
    public static getAgentsVisibility = (state: AppState) => getAgentsVisibility(state);
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    CreatePlayerRequirementState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller,
};
