import { PitchInisghtService } from 'api/agency/pitch-insight/agency-pitch-insight.service';
import { SuperPitchService } from 'api/super-pitch/super-pitch';
import { PlusPitchService } from 'api/plus-pitch/plus-pitch.service';
import TransfersOutInsightsService from 'api/transfers-out/insights/transfers-out-insights.service';
import {
    ActivityType,
    InsightsActivityType,
    InsightsFilterTypesEnum,
} from 'api/transfers-out/models/transfers-out-insights.model';
import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import { Selectors as TransferOutPlayerDetailSelector } from './transfer-out-player-detail.controller';
import { v4 } from 'uuid';
import { getAuth } from 'store/auth/authReducer';

export enum PaginationClickState {
    Number = 1,
    Left = 2,
    Right = 3,
}

class TransfersOutInsightsState {
    activities: InsightsActivityType[];
    currentActivities: InsightsActivityType[];
    activitiesState: 'loading' | 'success';
    selectedFilter: InsightsFilterTypesEnum;
    pageSize: number;
    currentPage: number;
    totalResultCount: number;
    totalPageCount: number;
    paginationState: PaginationClickState;
}

const defaultState: TransfersOutInsightsState = {
    activities: [],
    currentActivities: [],
    activitiesState: 'loading',
    selectedFilter: InsightsFilterTypesEnum.All,
    pageSize: 10,
    currentPage: 1,
    totalResultCount: 0,
    totalPageCount: 0,
    paginationState: PaginationClickState.Number,
};

const stateController = new StateController<TransfersOutInsightsState>(
    'SQUAD_TRANSFER_OUT/INSIGHTS_TAB',
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        };
    }

     public static getAllActivitiesInitialData() {
        return async (dispatch) => {
            try {
                await dispatch(Actions.getAllInsights());
                dispatch(Actions.initPagination());
            } catch (error) {
                console.error(error);
            }
        }
    }

      public static getPlayerActivitiesInitialData() {
        return async (dispatch) => {
            try {
                await dispatch(Actions.getPlayerInsights());
                dispatch(Actions.initPagination());
            } catch (error) {
                console.error(error);
            }
        }
    }

     public static initPagination() {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState());
            const activities = Selectors.getFilteredPlayerActivity(getState());
            dispatch(stateController.setState({
                pageSize: subState.pageSize,
                currentPage: 1,
                totalResultCount: activities.length,
                totalPageCount: Math.ceil(activities.length / subState.pageSize),
                currentActivities: activities.slice(0, subState.pageSize)
            }));
        }
    }

     public static setPage = (page: number) => {
        return (dispatch, getState: () => AppState) => {
            const { pageSize } = Selectors.getRoot(getState());
            const activities = Selectors.getFilteredPlayerActivity(getState());

            const itemOffset = ((page - 1) * pageSize) % activities.length;

            dispatch(stateController.setState((prevState) => {
                return {
                    ...prevState,
                    currentPage: page,
                    currentActivities: activities.slice(itemOffset, itemOffset + pageSize),
                }
            }));
        }
    }

    public static setPageSize = (page: number, pageSize: number) => {
        return (dispatch, getState: () => AppState) => {
            const { activities } = Selectors.getRoot(getState());
            const itemOffset = ((page - 1) * pageSize) % activities.length;

            dispatch(stateController.setState((draftState) => {
                return {
                    ...draftState,
                    pageSize,
                    currentPage: page,
                    totalPageCount: Math.ceil(activities.length / pageSize),
                    currentActivities: activities.slice(itemOffset, itemOffset + pageSize)
                }
            }));

        }
    }

    public static paginationSetState(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ paginationState: value }));
        }
    }


    public static getPlayerInsights() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    activitiesState: 'loading',
                }))
            );

            try {
                const player = TransferOutPlayerDetailSelector.getPlayer(getState());
                const playersActivity = await TransfersOutInsightsService.getPlayerActivity(
                    player.id
                );
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        activities: playersActivity.activity.map((v) => ({
                            ...v,
                            idFE: v4(),
                        })),
                    }))
                );
            } catch (error) {
            } finally {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        activitiesState: 'success',
                    }))
                );
            }
        };
    }


     public static getAllInsights() {
        return async (dispatch, _) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    activitiesState: 'loading',
                }))
            );

            try {
                const allActivities = await TransfersOutInsightsService.getActivity();
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        activities: allActivities.activity.map((v) => ({
                            ...v,
                            idFE: v4(),
                        })),
                    }))
                );
            } catch (error) {
            } finally {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        activitiesState: 'success',
                    }))
                );
            }
        };
    }

    public static setPlayerInsightsFilter(filter: InsightsFilterTypesEnum) {
    return (dispatch, getState: () => AppState) => {
        dispatch(
            stateController.setState((prevState) => ({
                ...prevState,
                selectedFilter: filter,
            }))
        );
        dispatch(Actions.initPagination());
    };
}

    public static testInterest(activity: InsightsActivityType) {
        return async (_, getState: () => AppState) => {
            const user = getAuth(getState());

            if (activity.regularPitchId) {
                await PitchInisghtService.testInterest(activity.regularPitchId);
            }

            if (activity.plusPitchSquadId) {
                await PlusPitchService.testInterest(activity.plusPitchSquadId);
            }

            if (activity.superPitchSquadId) {
                await SuperPitchService.testInterest(
                    activity.squadId,
                    user.userId,
                    activity.superPitchSquadId,
                    activity.superPitchSquadId
                );
            }
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersOut.insightsTab;
    public static getPlayerActivity = (state: AppState) => Selectors.getRoot(state).activities;
    public static getCurrentPage = (state: AppState) => Selectors.getRoot(state).currentPage;
    public static getPageSize = (state: AppState) => Selectors.getRoot(state).pageSize;
    public static getTotalRowCount = (state: AppState) => Selectors.getRoot(state).totalResultCount;
    public static getTotalPageCount = (state: AppState) => Selectors.getRoot(state).totalPageCount;
    public static getCurrentActivities = (state: AppState) => Selectors.getRoot(state).currentActivities;
    public static getFilteredPlayerActivity = (state: AppState) => {
        const selectedFilter = Selectors.selectedFilter(state);

        const filterFunction = (act: InsightsActivityType) => {
            switch (selectedFilter) {
                case InsightsFilterTypesEnum.SavedPitches:
                    return act.type === ActivityType.Saved;
                case InsightsFilterTypesEnum.ActionableItems:
                    return act.type === ActivityType.Saved && act.canTestInterest;
                case InsightsFilterTypesEnum.Interest:
                    return act.type === ActivityType.DeclaredInterest;
                case InsightsFilterTypesEnum.All:
                    return true;
            }
        };

        return Selectors.getPlayerActivity(state).filter(filterFunction);
    };
    public static isActivityLoading = (state: AppState) =>
        Selectors.getRoot(state).activitiesState === 'loading';
    public static isActivityEmpty = (state: AppState) =>
        Selectors.getPlayerActivity(state).length === 0;
    private static selectedFilter = (state: AppState) => Selectors.getRoot(state).selectedFilter;
    public static isFilterSelected = (state: AppState, filter: InsightsFilterTypesEnum) =>
        Selectors.selectedFilter(state) === filter;
    public static getFilterCount = (state: AppState, filter: InsightsFilterTypesEnum) => {
        const playerActivity = Selectors.getPlayerActivity(state);

        switch (filter) {
            case InsightsFilterTypesEnum.All:
                return playerActivity.length;
            case InsightsFilterTypesEnum.ActionableItems:
                return playerActivity.filter(
                    (activity) => activity.type === ActivityType.Saved && activity.canTestInterest
                ).length;
            case InsightsFilterTypesEnum.Interest:
                return playerActivity.filter(
                    (activity) => activity.type === ActivityType.DeclaredInterest
                ).length;
            case InsightsFilterTypesEnum.SavedPitches:
                return playerActivity.filter((activity) => activity.type === ActivityType.Saved)
                    .length;
            default:
                const val: never = filter;
                return val;
        }
    };
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    TransfersOutInsightsState as State,
    Actions as Actions,
    stateController as Controller,
};
